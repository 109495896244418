<template>
    <div>
        <headTitle :isMain="4"/>
        <img class="mobile-example-headImg" src="../../assets/image/mobile/02-8销售运营管理.png"/>
        <div class="mobile-head-explain" style="margin-top:-22rem">
            <img class="head-img-logo" style="margin-left:4rem" src="../../assets/image/selected/08-2iCRM .png"/>
            <div class="mobile-head-explain-title">销售运营管理</div>
            <div class="mobile-head-explain-content" style="font-size: 3.2rem;">IQVIA iSales IQVIA iCRM 是专为生命科学行业设计打造的销售运营管理解决方案</div>
        </div>
        <div class="mobile-content-box1">
            <div class="ware-content1" style="margin-top:5rem;text-align: justify;">IQVIA iCRM 是专为生命科学行业设计打造的销售运营管理解决方案。
                    支持多部门协作及信息共享，多渠道触达客户。助力企业快速适应不断变化的商业需求，实现新数字化时代下的客户触达和销售运营管理。</div>
            <div class="saled-box2">
                <div class="container">
                    <div class="card">
                        <img src="../../assets/image/selected/08-2多部门协作.png"/>
                        <div class="title">多部门协作<br/>
                            多渠道触达</div>
                    </div>
                    <div class="card">
                        <img src="../../assets/image/selected/08-2合规辅助.png"/>
                        <div class="title" style="margin-top:0.8rem">合规辅助甄别帮助企业获取更真实客户反馈</div>
                    </div>
                    <div class="card">
                        <img src="../../assets/image/selected/08-2数据驱动.png"/>
                        <div class="title">数据驱动，智能推荐<br/>
                            实时监控，优化行为</div>
                    </div>
                </div>
                <img class="saled-box2-img" src="../../assets/image/selected/08-2中图.png"/>
            </div>
        </div>
        <mobileFoot/>
    </div>
</template>

<script>
import headTitle from '../../components/mobile/head-title.vue'
import mobileFoot from '../../components/mobile/mobile-foot.vue'
import mobileCarousel from '../../components/mobile/mobile-carousel.vue'
import Swiper from "swiper";
export default {
    name: 'BizfocusIndexMobileIndex',
    components:{headTitle,mobileCarousel,mobileFoot},
    data() {
        return {
            checkIndex: 1,
            showDescription:false,
            box2ImageList:[
                {
                    index:0,
                    urlImg:require("../../assets/image/index/01-15GF整合数据分析系统.png"),
                    title:'5GF整合数据分析系统',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-5GF'
                },
                {
                    index:1,
                    urlImg:require("../../assets/image/index/01-1数字化产线.png"),
                    title:'数字化产线', //右转是0
                    content:'打造透明、高效、稳定的数字化生产管理平台',
                    route:'/selected-line'
                },
                {
                    index:2,
                    urlImg:require("../../assets/image/index/01-1市场渗透率分析.png"),
                    title:'市场渗透率分析',
                    content:'全球某化妆品巨头',
                    route:'/selected-marykay'
                },
                {
                    index:3,
                    urlImg:require("../../assets/image/index/01-1智能仓储.png"),
                    title:'智能仓储',
                    content:'布局原料赋码，助力品质生产',
                    route:'/selected-warehousing'
                },
                {
                    index:4,
                    urlImg:require("../../assets/image/index/01-1Dashboard数据实施.png"),
                    title:'Dashboard数据实施',
                    content:'全球某知名乳制品公司',
                    route:'/selected-dashboard'
                },
                {
                    index:5,
                    urlImg:require("../../assets/image/index/01-1商情诊断管理分析.png"),
                    title:'商情诊断管理分析',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-business'
                },
                {
                    index:6,
                    urlImg:require("../../assets/image/index/01-1iDAS整合数据分析系统.png"),
                    title:'iDAS整合数据分析系统', //左转是0
                    content:'韩国某知名化妆品集团',
                    route:'/selected-iDAS'
                },

            ],
        };
    },

    mounted() {
    },

    methods: {
        jumpMobileRoute(route){
            this.$router.push(route)
            console.log(route)
        }
    },
};
</script>

<style lang="less" scoped>
.description{
    display: flex;
    width: 66.5rem;
    margin: auto;
    margin-top: 6rem;
    .title1{
        font-size: 2.8rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #11A84F;
        line-height: 4.2rem;
    }
    .title2{
        font-size: 2.8rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #11A84F;
        line-height: 4.2rem;
        margin-left: 70%;
    }
}
.description-box{
    max-width: 66.5rem;
    margin: auto;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 5rem;
}
.mobile-head-catalogue{
    width: 100%;
    height: 9rem;
    display: flex;
    background: #F8F8F8;
    .title1{
        width: 20rem;
        height: 9rem;
        display: flex;
        text-align: center;
        align-items: center;
        span{
            font-size: 2.8rem;
            font-family: 'CN_Medium';
            font-weight: 500;
            color: #4F4F4F;
            line-height: 2rem;
            margin: auto;
        }
    }
    .ischecked{
        background: #EDEDED;
        border-bottom: 2px solid #11A84F;
    }
}
.mobile-content-box1{
    width: 66.5rem;
    margin: auto;
    margin-top: 3rem;
}
.ware-content1{
    max-width: 66rem;
    // height: 10.6rem;
    margin: auto;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 5rem;
}
.ware-title{
    font-size: 2.8rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 4.2rem;
}
.ware-box1{
    width: 66.8rem;
    height: 10rem;
    border: 1px solid #CCCCCC;
    margin: auto;
    margin-top: 1.3rem;
    display: flex;
    .title1{
        width: 58.8rem;
        height: 4rem;
        font-size: 2.8rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #666666;
        line-height: 4rem; 
        margin: auto; 
        align-items: center;
    }
}
.ware-img{
    width: 100%;
    height: 40.7rem;
}
.ware-img2{
    width: 100%;
    height: 63.3rem;
}
.saled-box2{
    width: 100%;
    margin: auto;
    margin-bottom: 5rem;
    .container{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .card{
            width: 20rem;
            display: flex;
            margin-top: 5rem;
            margin-bottom: 5.6rem;
            img{
                width: 7rem;
                height: 7rem;
                float: left;
            }
            .title{
                margin-left: 2.1rem;
                width: 18rem;
                font-size: 1rem;
                font-family: 'CN_Normal';
                text-align: justify;
                margin-top: 1.5rem;
                font-weight: 400;
                color: #666666;
                line-height: 2rem;
            }
        }
    }
    .saled-box2-img{
        width: 100%;
        // height: 61.6rem;
    }
}
</style>